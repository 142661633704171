<template>
  <div class="container">
    <div class="text-right my-4">
      <loading :active.sync="isLoading" :can-cancel="false" loader="dots" color="#007bff" :height="45" :width="75"
        :is-full-page="fullPage"></loading>

      <div class="text-right">
        <v-container fluid>
          <form>
            <v-row>
              <v-col cols="3" class="my-4">
                <v-select :items="digital_channel" dense v-model="digital_channel_data" label="Digital Channel"
                  @change="searchModuleName">
                </v-select>
              </v-col>
              <v-col cols="3">
                <!-- <v-text-field v-model="panelist_id" label="Enter Panelist Id"
                  v-on:keyup.enter="searchDigital()"></v-text-field> -->

                  <v-combobox
                    v-model="panelist_id" 
                    label="Enter Panelist Id"
                    v-on:keyup.enter="searchDigital()"
                    class="noicon-combo"
                    :items="panelist_id_autofills"
                    ref="panelist_id"
                  />
                <div style="color: red">
                  {{ message1 }}
                </div>
              </v-col>
              <v-col cols="3" class="my-4" style="text-align: left">
                <v-btn small color="primary" class="mx-2" @click="searchDigital()">
                  Search
                </v-btn>

                <v-btn small color="default" class="mx-2" @click="reset()">
                  Reset
                </v-btn>
              </v-col>

              <v-col cols="3" class="text-right my-4">
                <v-dialog max-width="600" v-model="dialog2">
                  <template v-slot:activator="{ on, attrs }">
                    <div style="float:right">
                      <v-btn color="error" small v-bind="attrs" v-on="on"
                        v-show="checkIfOperationExistForModule('delete')">Delete</v-btn>
                    </div>
                    <div style="float:right">
                      <v-btn color="primary" small @click.stop="mobilemassproductupdate()">Capture</v-btn>
                    </div>
                  </template>
                  <div v-if="selected.length > 0">
                    <template>
                      <v-card>
                        <v-card-text>
                          <div class="text-h3 pa-12">
                            Are you sure you want to delete all the selected
                            Record(s)?
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-form ref="form" method="post">
                            <v-btn text class="confirm_button mx-2" v-on:click="dialog2 = false"
                              @click="deleteMobileDigital">Yes</v-btn>
                          </v-form>
                          <v-btn class="close_button mx-2" text @click="dialog2 = false">
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </div>
                  <div v-if="selected.length == 0">
                    <template>
                      <v-card>
                        <v-card-text class="center">
                          <div class="text-h3 pa-12">
                            Please Select Record(s) to delete
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn class="close_button" text @click="dialog2 = false">
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </div>
                </v-dialog>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </div>
    </div>
    <div class="text-right"></div>
    <v-row>
      <v-col cols="12">
        <CopyToClipboard :filter="Prefilter" :muids="selectedEndPointUrls" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="tableWrapper">
          <v-data-table :headers="headers" item-key="creative_id" :single-select="singleSelect" show-select
            v-model="selected" :items="mobiledata" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :options.sync="options"
            :server-items-length="total" :loading="isLoading" class="elevation-1" hide-default-footer>
            <template v-slot:item.creative_path="{ item }">
              <video v-if="item.digital_channel == 'Online Video'" style="
                  max-height: 150px;
                  max-width: 300px;
                  padding: 3px 0px 3px 0px;
                  overflow: scroll;
                " controls :src="item.creative_path"></video>

              <iframe v-else-if="item.file_type == 'HTML'" style="
                  max-width: 300px;
                  max-height: 100px;
                  padding: 3px 0px 3px 0px;
                  overflow: scroll;
                " :src="item.creative_path">
              </iframe>

              <div v-else style="
                  max-height: 100px;
                  max-width: 300px;
                  margin: 3px 0px 3px 0px;
                  overflow: scroll;
                ">
                <img :src="item.creative_path" />
                <!-- <v-img class="border border-dark"
                    :lazy-src="item.creative_path"
                    :src="item.creative_path" >
                    <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
                        </v-row>
                    </template>

                </v-img> -->
              </div>
            </template>
            <template v-slot:item.viewlink="{ item }">
              <div v-if="item.file_type == 'html' || item.file_type == 'HTML'"
                @click="open_html_popup(item.creative_path)" style="cursor: pointer">
                View
              </div>
              <div v-else @click="open_img_popup(item.creative_id)" style="cursor: pointer">
                View
              </div>
            </template>
            <template v-slot:item.device="{ item }">
              <span>{{ item.device ? item.device : "Not Exist" }}</span>
            </template>
          </v-data-table>
          <div class="text-center pt-4 pb-4">
            <Pagination :isloading="isLoading" :startRecord="startRecord" :currentPage="currentPage" :lastPage="lastPage"
              :lastRecord="lastRecord" :totRecords="totRecords" :isCurrentPageClass="isCurrentPageClass"
              :perpage="perpage" :getLastPageClass="getLastPageClass" :totPage="totPage" :getPages="getPages"
              @handlePerPage="handlePerPage" @paginate="paginate" @last="last" @getDataByPage="getDataByPage"
              :showPerPage="showPerPage" :showGoTo="showGoTo"/>
          </div>

          <v-dialog v-model="mobilemassupdatedialog" max-width="650">
            <MobileDigitalTemp :selected="selected" @mobilemassupdatedialogStatus="mobilemassupdatedialogStatus" />
          </v-dialog>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="toggleUpdateModal" max-width="600"> </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import Pagination from "./component/Pagination.vue";
// import videoPlayer from 'vuetify-media-player/src/components/video-player.vue'
// import 'vuetify-media-player/src/style.styl'
// import VueDPlayer from 'vue-dplayer'
// import 'https://unpkg.com/browse/vue-dplayer@0.0.10/dist/vue-dplayer.css'
import MobileDigitalTemp from "./component/MobileDigitalTemp";
import CopyToClipboard from "./component/CopyToClipboard.vue";

export default {
  // components: { Index, Buttons, Loading, Pagination,videoPlayer },
  components: { Index, Buttons, Loading, Pagination, CopyToClipboard, MobileDigitalTemp },
  data() {
    return {
      showGoTo:true,
      sortDesc: true,
      sortBy: "ts",
      Prefilter: 'Prefilter',
      mobilemassupdatedialog: false,
      toggleUpdateModal: false,
      //////table data starts here////
      selected: [],
      singleSelect: false,
      digital_channel: [
        "All",
        "Online Display",
        "Search Engine Marketing",
        "Online Video",
      ],
      digital_channel_data: "All",
      options: {},
      sort: "",
      total: 0,
      editReportMode: false,
      fromDateMenu: false,
      fromDateVal: null,
      panelist_id: "",
      //  minDate: "2020-01-05",
      //  maxDate: "2019-08-30",

      headers: [
        {
          text: "Creative Path",
          align: "start",
          value: "creative_path",
          width: "320px",
          class: "v-data-table-header",
          sortable: false,
        },
        {
          text: "View",
          value: "viewlink",
          width: "80px",
          class: "v-data-table-header",
          sortable: false,
        },
        {
          text: "Panelist ID",
          value: "panelist_id",
          width: "120px",
          class: "v-data-table-header",
          sortable: false,
        },

        {
          text: "Channel",
          value: "digital_channel",
          width: "100px",
          class: "v-data-table-header",
          sortable: false,
        },
        {
          text: "File Type",
          value: "file_type",
          width: "100px",
          class: "v-data-table-header",
          sortable: false,
        },
        {
          text: "Device",
          value: "device",
          width: "100px",
          class: "v-data-table-header",
          sortable: false,
        },
        {
          text: "Date",
          value: "ts",
          width: "120px",
          class: "v-data-table-header",
          sortable: true,
        },
      ],

      ////// table data ends here
      valid: true,
      checkbox: false,
      operation: [],
      modules: [],
      mobiledata: [],
      isLoading: true,
      fullPage: true,
      dialog: false,
      dialog1: false,
      dialog2: false,
      deleteItems: [],
      checked_Id: [],
      checkcedstr: "",
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      parent_id: [],
      module_sort: [],
      allSelected: false,
      new: [],
      panel_data: [],
      addModule: {
        moduleName: null,
        parent_id: null,
        module_icon: "mdi-view-dashboard",
        parent_id: null,
        url_slug: null,
        panel_id: null,
      },

      checked: false,
      nameRules: [(v) => !!v || "Name is required"],
      urlRules: [(v) => !!v || "Url is required"],
      addModulelabel: null,
      //pagination code
      perpage: 30,
      totRecords: null,
      page: 1,
      isLoading: true,
      fullPage: true,
      query: null,
      showPerPage: false,
      panelist_id_autofills: JSON.parse(localStorage.getItem("panelist_id_autofills")) ||  [],
      ///
    };
  },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    ///////
    selectedEndPointUrls: function () {
      let endpoints = [];
      var view = this;
      if (view.selected.length > 0) {
        view.mobiledata.forEach(function (item) {
          view.selected.forEach((o) => {
            let enpointURL = ""
            if ((item.file_type == 'html' || item.file_type == 'HTML') && (item.creative_path == o.creative_path)) {
              enpointURL = item.creative_path;
              endpoints.push({ url: enpointURL });
            }
            else if (item.creative_id == o.creative_id) {
              enpointURL = view.$url("CLIENT_FRONTEND") + "mobilepreview/" + item.creative_id + "&product_status=11&creative_id=" + item.creative_id;
              enpointURL = enpointURL.replace(/%20/g, " ");
              endpoints.push({ url: enpointURL });
            }

          });

        });
      }
      // console.log([...new Set(endpoints.map(({url}) => url))].map((url) => {return {url}}))  
      return [...new Set(endpoints.map(({ url }) => url))].map((url) => { return { url } });

    }
  },
  methods: {
    open_html_popup(url) {
      window.open(
        url,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,,width=800,height=800"
      );
    },
    mobile_preview(creative_id) {
      let url =
        this.$url("CLIENT_FRONTEND") +
        "mobilepreview/" +
        creative_id +
        "&product_status=11&creative_id=" +
        creative_id;
      url = url.replace(/%20/g, " ");
      window.open(
        url,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,,width=800,height=800"
      );
    },
    play() {
      console.log("play callback started");
    },
    //pagination methods
    checkIfOperationExistForModule(type) {
      // console.log(this + "-----" + type);
      return true;
      return this.$utils.checkIfOperationExistForModule(this, type);
    },
    getDataByPage(value) {
      // console.log(value);
      this.page = value;
      this.getMobileDigital(this.page);
    },
    handlePerPage(value) {
      // console.log(value);
      this.perpage = value;
      this.getMobileDigital(this.page);
    },
    open_img_popup(creative_id) {
      var view = this;
      let url =
        this.$url("CLIENT_FRONTEND") +
        "mobilepreview/" +
        creative_id +
        "&product_status=11&creative_id=" +
        creative_id;
      url = url.replace(/%20/g, " ");
      window.open(
        url,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,,width=600,height=600"
      );
    },
    paginate(n) {
      // console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getMobileDigital(this.page);
    },
    last(value) {
      // console.log(value);
      this.page = this.lastPage;
      this.getMobileDigital(this.page);
    },

    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    validate() {
      this.$refs.form.validate();
    },

    getMobileDigital(page = "", query = "") {
      let view = this;
      view.selected = [];
      const path = view.$url("MOBILE_DIGITAL");
      // const path = 'http://0.0.0.0:5054/mobile-digital';
      this.$nextTick(() => {
        if(this.$refs.panelist_id !== undefined ){
          this.$refs.panelist_id.isMenuActive = false;
        }
      })
        if(view.panelist_id.length >0)
        {
          if(view.panelist_id_autofills.length > 99){
            view.panelist_id_autofills.shift();
           }
           view.panelist_id_autofills.push(view.panelist_id);
           localStorage.setItem("panelist_id_autofills", JSON.stringify(view.panelist_id_autofills));
        } 
      view.isLoading = true;
      let data = {
        page: view.page,
        perpage: view.perpage,
        digital_channel: view.digital_channel_data,
        panelist_id: view.panelist_id,
      };
      if (view.$store.getters["MdTempProduct/getSearch"]['sorting']) {
        data['sort'] = view.$store.getters["MdTempProduct/getSearch"]['sorting'];
      }
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
        .post(path, data)
        .then((res) => {
          view.isLoading = false;
          let payload = res.data.payload;
          this.total = payload.length;
          this.totRecords = res.data.totaldata;
          this.mobiledata = payload;
          // this.pageNo = res.data.lastPage;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    addToDelete(e, atchid) {
      this.checkedthis = false;
      if (e.target.checked) {
        if (this.checked_Id.indexOf(e.target.value) == -1) {
          this.checked_Id.push(e.target.value);
        }
      } else {
        this.checked_Id.splice(this.checked_Id.indexOf(e.target.value), 1);
      }
      let checkcedstr = this.checked_Id.join(",");
      this.checkcedstr = checkcedstr;
      // console.log(this.checkcedstr);
    },
    deleteMobileDigital(e) {
      e.preventDefault();
      let view = this;
      view.isLoading = true;
      // console.log(this.selected);
      var ids_obj = { id: this.selected };
      let idarr = [];
      for (const selectitem in this.selected) {
        idarr.push(this.selected[selectitem].creative_id);
      }
      this.$fetch({ requiresAuth: true, operation: "delete", vueScope: view })
        .delete(this.$url("MOBILE_DIGITAL"), {
          params: { id: idarr },
        })
        .then((response) => {
          view.isLoading = false;
          if (response.data.status_code == 200) {
            view.$swal.fire({
              icon: "success",
              title: "Selected record(s) has been deleted",
              text: response.data.msg,
            });
          }
          view.getMobileDigital();
          view.selected = [];
        })
        .catch((error) => {
          console.error(error);
          view.isLoading = false;
          this.$swal.fire({
            icon: "error",
            title: "Error",
            text: "Something went wrong!",
          });
        });
    },

    selectAll: function () {
      let mobiledata = this.mobiledata;
      if (this.allSelected == true) {
        if (Array.isArray(mobiledata) && mobiledata.length) {
          this.selected = [];
          let i;
          for (i = 0; i < mobiledata.length; i++) {
            // console.log(mobiledata[i]);
            this.selected.push(mobiledata[i][0]);
          }
        }
      } else if (this.allSelected == false) {
        this.selected = [];
      }
    },
    select: function () {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
    searchModuleName: function () {
      this.page = 1
      this.getMobileDigital(this.page, this.query);
      // console.log(this.query);
    },
    reset() {
      var view = this;
      view.panelist_id = "";
      view.digital_channel_data = "All";
      view.page = 1;
      view.sortDesc = true,
        view.sortBy = "ts",
        this.getMobileDigital();
    },

    mobilemassupdatedialogStatus(o) {
      var view = this;
      view.mobilemassupdatedialog = false;
      view.selected = [];
      if (o == "save") view.getMobileDigital();
    },
    mobilemassproductupdate() {
      var view = this;
      if (view.selected.length > 0) {
        view.mobilemassupdatedialog = true;
      } else {
        view.$swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "Please select ProductID",
        });
      }
    },
    searchDigital() {
      var view = this;
      view.page = 1;
      setTimeout(() => view.getMobileDigital(), 100)
      // console.log("click here");
    },
  },
  watch: {
    "$store.state.Rawdata.current_active_module": function (o) {
      if (o) {
        //PATCH for JIRAID:3269
        // below line has been commented as the service was called twice
        //this.getMobileDigital(1);
      }
    },
    options: {
      handler(o) {
        var view = this,
          sortBy = o.sortBy,
          sortDesc = o.sortDesc;
        if (Array.isArray(sortBy) && Array.isArray(sortDesc)) {
          if (sortBy.length > 0 && sortDesc.length > 0) {
            view.$store.dispatch("MdTempProduct/setSorting", {
              field: sortBy[0],
              direction: sortDesc[0],
            });
            view.page = 1;
            view.getMobileDigital();

          }
        }
      },
    }
    //  page: function (ob) {
    //  this.getMobileDigital(ob);
    //  },
  },
};
</script>

<style>
.noicon-combo .v-select__slot .v-input__icon {
      display: none !important;
    }
</style>
